/*.App {
  text-align: center;
}*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.offcanvas-end {
    width: 100% !important;
    border: none;
}

.background-hero-gradient {
    height: 400px;
    max-width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.background-hero {
    height: 400px;
    background-image: url('../public/assets/hero.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.h1-large {
    font-size: 4rem;
}

.align-self-flex-end{
    align-self: flex-end;
}

.home-card-bg--electricals {
    height: 400px;
    background-image: url('../public/assets/electricals.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.home-card-bg--mechanical {
    height: 400px;
    background-image: url('../public/assets/plumbing.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.btn-block {
    display: block;
    width: 100%;
    border-radius: 0;
    height: 50px;
}

.footer-img--NAPIT {
    height: 75px;
    width: 100px;
    background-image: url('../public/assets/napit-logo-2017.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

footer{
    height: 150px;
}

.about-first-image {
    height: 250px;
    background-image: url('../public/assets/connor.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.logo-img {
    height: 100px;
    width: 120px;
    background-image: url('../public/assets/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #dc3545;
    color: white !important;
}

#basic-nav-dropdown {
    color: white !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.navbar {
    margin: 0;
    padding: 0;
}

.navbar-nav {
    position: absolute;
    top: 0;
}

#offcanvasNavbarLabel {
    display: none;
}

.offcanvas-header {
    justify-content: flex-end;
}

.btn-close{
    background-color: white;        
}